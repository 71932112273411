import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import '../css/index.scss';

//import Header from '../components/Header';

import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, {Autoplay } from 'swiper';

import Grid from '@material-ui/core/Grid';

import 'swiper/swiper-bundle.min.css';

import {display_contactBubble,seo_tags,scroll_top} from '../js/helper.js';

import CardMedia from '@material-ui/core/CardMedia';

import Hidden from '@material-ui/core/Hidden';

SwiperCore.use([Autoplay]); 

const useStyles = makeStyles((theme) => ({
  swiper_images:{
      width:'85%',
      height:'auto',
      border: '0px solid blue'
  },
  menu_icon:{
      float:'left',
      fontSize:'40px'
  }
}));

function Home() {
    seo_tags();
    const classes = useStyles();

    if(window.innerWidth>767){
        var swiperDelayVal = 4000;
    }else{
         swiperDelayVal = 3000;
    }

    const [swiperDelay, setSwiperDelay] = useState(swiperDelayVal);
    const [swiperImg, setSwiperImg] = useState([]);

    useEffect(() => {
        scroll_top();
        display_contactBubble();

       //var img=new Image();
        //img.src="./homepage_slider/slide2_gif.gif";

         fetch(process.env.REACT_APP_API_DOMAIN+"banners")
		.then(res => res.json())
		.then(
			(results) => {
				console.log("banner", results);
                let bannerArray = results.filter((v, i) => i !== 0 );
                bannerArray.push(results[0]);
                const todoItems = bannerArray.map((result) =>

                    <SwiperSlide key={result.id} className="swiper-slide">
                        <img id="slide_1" className="slider_image" src={result.filename} alt="" />
                    </SwiperSlide>
                    
                );

                setSwiperImg(todoItems);
			}
		)

        
	},[]);


   

    function window_resized(){
        updateSwiperDelay();
    }

    function updateSwiperDelay(){
        if(window.innerWidth>760){
            /* desktop site */
            setSwiperDelay(7000);
        }else{
            /* mobile site */
            setSwiperDelay(4000);
        }
    }

    function swiper_initialized(){
        updateSwiperDelay();
    }

    return (
        <div className="homePage">
            <Grid  container>
                <Grid item containers="true" xs={12}>
					{swiperImg.length > 0 && (
						<Swiper className="swiper-container" loop={true}  autoplay={{ delay: swiperDelay, disableOnInteraction: false}} speed={1000}
						onSlideChangeTransitionEnd={(swiper) => swiper.activeIndex} onResize={(swiper) => window_resized()} onInit={(swiper) => swiper_initialized()}>
							{swiperImg}						
						</Swiper>
					)}
				</Grid>
			</Grid>
			
			<Grid container className="maxWrapper technology-list" spacing={3}>
				<Hidden smDown>
					<Grid item xs={12} md={4} className={classes.news_title}>
						<CardMedia
							component="img"
							alt=" "
							image='../lampard.png'
							title=""
							className="tech-stack"
						/>
					</Grid>
					<Grid item xs={12} md={4} className={classes.news_title}>
						<CardMedia
							component="img"
							alt=" "
							image='../wordpress.png'
							title=""
							className="tech-stack"
						/>
					</Grid>
					<Grid item xs={12} md={4} className={classes.news_title}>
						<CardMedia
							component="img"
							alt=" "
							image='../server.png'
							title=""
							className="tech-stack"
						/>
					</Grid>
					<Grid item xs={12} md={4} className={classes.news_title}>
						<CardMedia
							component="img"
							alt=" "
							image='../internate.png'
							title=""
							className="tech-stack"
						/>
					</Grid>
					<Grid item xs={12} md={4} className={classes.news_title}>
						<CardMedia
							component="img"
							alt=" "
							image='../digital.png'
							title=""
							className="tech-stack"
						/>
					</Grid>
					<Grid item xs={12} md={4} className={classes.news_title}>
						<CardMedia
							component="img"
							alt=" "
							image='../wix.png'
							title=""
							className="tech-stack"
						/>
					</Grid>
					<Grid item xs={12} md={4} className={classes.news_title}>
						<CardMedia
							component="img"
							alt=" "
							image='../interactive-3D-models.png'
							title=""
							className="tech-stack"
						/>
					</Grid>
					<Grid item xs={12} md={4} className={classes.news_title}>
						<CardMedia
							component="img"
							alt=" "
							image='../content-creation.png'
							title=""
							className="tech-stack"
						/>
					</Grid>
					<Grid item xs={12} md={4} className={classes.news_title}>
						<CardMedia
							component="img"
							alt=" "
							image='../augmented-reality.png'
							title=""
							className="tech-stack"
						/>
					</Grid>
				</Hidden>
				
				<Hidden mdUp>
					<Grid item className={classes.news_title} xs={12} sm={12} md={5} lg={5}>
						<img className="tech-stack" src="./technology-stack-v1.png" alt=""/>
					</Grid>
				</Hidden>
			</Grid>
        </div>
    )
}

export default Home
