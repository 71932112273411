import React,{useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import {scroll_top,seo_tags} from '../js/helper.js'


const useStyles = makeStyles((theme) => ({
  padded_para:{
    paddingBottom:'30px',
	textAlign:'left'
  },
  page_title: {
    textAlign: 'center',
    fontSize: '18px;',
    fontFamily:'Rajdhani',
    color: '#000'
  },
  container_div: {
    padding:'0 40px',
    border:'0px solid black'
  },
  sub_title:{
	  fontSize:'18px',
	  fontFamily:'rajdhani',
	  padding:'20px 0'
  },
  sub_para:{
	  fontSize:'18px',
	  fontFamily:'rajdhani'
  },
  unordered_list:{
	  paddingLeft:'15px'
  }
}));

function DevelopmentTerms() {
    seo_tags({
      title: 'Website Development Terms & Conditions'
    });

    const classes = useStyles();
    useEffect(() => {
		scroll_top();
	},[]);

    return (
        <div className ="maxWrapper">
			<Grid className={classes.container_div} container px={5}>
				<Grow in={true}>
					<Grid className={classes.page_title} item xs={12}>
                        <h1>Website Development Terms & Conditions</h1>
					</Grid>
				</Grow>


                <Grow in={true}>
					<Grid className={classes.padded_para} item xs={12}>
                        <ol>
                            <li><p><a href="#projectTerms">Project terms</a> </p></li>
                            <li><p><a href="#prterms">Payment / Refund terms</a> </p></li>
                            <li><p><a href="#Iproperty">Intellectual Property</a> </p></li>
                            <li><p><a href="#confidential">Confidentiality</a> </p></li>
                            <li><p><a href="#dli">Disputes, Liability and Indemnity</a> </p></li>
                            <li><p><a href="#glaw">Governing Law</a> </p></li>
							<li><p><a href="#disclaimer">Disclaimer</a> </p></li>                            
                        </ol>

						<ol><span name="projectTerms">&nbsp;</span>
			<li><h4 id="projectTerms">Project terms</h4>
				<ul className ="listpadding">
					<li><p>During the project briefing, it is Client’s responsibility to provide us with clear guidelines along with the flow or specific details you may require. When such details are not provided, we will proceed with our understanding of your requirements and quote accordingly. At a later stage, if a discrepancy arises, it may lead to additional costs to accommodate the changes. Thus, it is essential that you clarify every aspect of your website development and ensure that you have been quoted on the right requirements.</p></li>
					<li>
					<p>
					All proposals remain valid for THIRTY (30) days from the date of issue by sapnagroup to the Client unless otherwise agreed in writing by sapnagroup. An order or instruction to commence development from the Client shall not be considered a contract between the Client and sapnagroup unless accepted by sapnagroup whereupon these Terms and Conditions will be deemed to have been accepted unconditionally by the Client.
					</p></li>
					<li>
					<p>
					Once proposal has been finalised, any additions, changes or enhancements in the functionality or design of the project will affect the proposal and may incur additional cost and a revised delivery date. All additional work, over and above the estimates is charged separately. Under no circumstances will sapnagroup be liable for any delays caused by change in the project brief.
					</p></li>
					<li>
					<p>
					Any complexity related to specific deliverable, must be advised in advance and included in the proposal for costing purposes. We operate in good faith and rely on our Clients to disclose the full picture of their expectations. Any discrepancy arising due to unclear requirements or expectations will not be borne by sapnagroup.
					</p></li>
					<li>
					<p>
					Website content and all related materials need to be provided to us at the agreed dates. Any delays thereafter may delay the project and may incur additional charges if it goes beyond reasonable timeframe.
					</p></li>
					<li>
					<p>
					The Client must recognise that at times there may be unforeseen circumstances that will delay the development process, particularly with reference to the integration of third party software. We will try our best to complete the project as agreed in the proposal. As long as it is within a reasonable period, the Client agrees not to penalise us for any genuine delay, when every effort to keep the project on the proposed schedule is taken.
					</p></li>
					<li>
					<p>
					Whilst every endeavour will be made to ensure that the website and any scripts or programs are free of errors, sapnagroup cannot accept responsibility for any losses incurred due to malfunction, the website or any part of it.
					</p></li>
					<li>
					<p>
					The Client is expected to test fully any application or programming relating to a site developed by sapnagroup before being made generally available for use. Where “bugs”, errors or other issues are found after the site is live, sapnagroup will endeavour to correct these issues to meet the standards of function outlined in the brief.
					</p></li>
					<li>
					<p>
					sapnagroup will not be liable for any costs incurred, compensation or loss of earnings due to the work carried out on behalf of the Client or any of the Client’s appointed agents.
					</p></li>
					<li>
					<p>
					sapnagroup will not be liable for any costs incurred, compensation or loss of earnings due to the unavailability of the site, its servers or software.
					</p></li>
					<li>
					<p>
					Unless there is a maintenance contract agreed, sapnagroup will not be responsible for any code upgrades after the website has gone live.
					</p></li>
					
					<li>
					<p>
					sapnagroup will not be liable for any of the third party products, software or components used in the website development such as payment gateway, maps API, etc.
					</p></li>
					
					<li>
					<p>
					sapnagroup will not be liable for any open source products such as WordPress, CKEditor etc. It is Client’s responsibility to request us to update all components and third party softwares.
					</p></li>
					<li>
					<p>
					sapnagroup offer packages in co-ordination with third party providers. Changes to the third party provider’s rules and policies may ultimately affect the services we provide or the terms and conditions herein.
					</p></li>
					<li>
					<p>
					If open source components are used, the relevant licences apply.
					</p></li>
					<li>
					<p>
					Hosting and domain registration charges are not included as a part of the proposal unless mentioned otherwise. sapnagroup can offer our own or organise an appropriate external hosting solution if required.
					</p></li>
					<li>
					<p>
					Where Clients decide to organise their own hosting, we should be consulted before finalising the type of hosting and database, as it should meet the requirements of the technology used for the development. Please note that we will require full access with hosting support for testing and deploying the application. sapnagroup will not be liable for any delays or errors caused by direct or indirect actions of the hosting company.
					</p></li>
					<li>
					<p>
					sapnagroup cannot give access to their test servers and test websites to the Clients or any third party.
					</p></li>
					<li>
					<p>
					Technology support of additional 5 working hours is given to all medium and large size projects, for any basic modification or changes, which may be utilised for up to a period of one month after the delivery. Please note that these support hours are not applicable if the design or coding or any other aspect of the website has been altered by anyone other than the sapnagroup team.
					</p>
					</li>
					<li>
					<p>
					Client understands that the contact details of the Clients and all parties (contractors, suppliers etc) involved and the communications may be shared outside the EEA as well with our staff members and suppliers etc, only for the purpose of executing our service. The sharing of these details will enable understanding the requirements and help communicate with all the relevant parties. This will also include sensitive access details like passwords to databases, FTP accounts, google analytics etc as and where relevant.
					</p>
					</li>
				</ul>
			</li>
			<span id="prterms"></span>
				<li><h4>Payment / Refund terms</h4>
					<ul className ="listpadding">
						<li><p>All prices are quoted in GBP or EUR exclusive of VAT unless specified.</p></li>
						<li>
						<p>
						Invoices are issued based on project progress at the end of each month. Payments can be made via cheque or bank transfer. Payment terms are 30 days after receipt of the invoice via email.
						</p></li>
						<li>
						<p>
						The website, graphics and any programming code remain the property of sapnagroup until all outstanding accounts are paid in full.
						</p>
						</li>
						
					</ul>
				</li>
			<span id="Iproperty"></span>
				<li><h4>Intellectual Property</h4>
					<ul className ="listpadding">
						<li><p>The project related copyrights can be transferred to the Client, after the full payment of the project and upon signing the Certificate of Acceptance. sapnagroup reserves the rights to refuse the transfer of ownership if irregular circumstances arise.</p></li>
						<li>
						<p>
						Copyrights handed over to the Client include rights to re-use the code for another website or re-sell the programming codes for any commercial or non-commercial purposes. In the case of business restructuring or ownership change, ownership of the website can be transferred from one owner to another.
						</p></li>
						<li>
						<p>
						sapnagroup cannot take responsibility for any copyright infringements caused by materials submitted by the Client or added by the client to the website via the CMS (Content Management System). We reserve the right to refuse any material of a copyrighted nature unless adequate proof is given of permission to use such material.
						</p>
						</li>
						
					</ul>
				</li>
				<span id="confidential"></span>
				<li><h4>Confidentiality</h4>
					<ul className ="listpadding">
						<li><p>Unless notified in writing by the Client any information or documentation supplied by the Client to sapnagroup shall not be deemed as confidential.</p></li>
						
					</ul>
				</li>
				<span id="dli"></span>
				<li><h4>Disputes, Liability and Indemnity</h4>
					<ul className ="listpadding">
						<li><p>In an event of a dispute, sapnagroup reserves the right to charge the Client in full for the work done as well as for the resources spent in managing the dispute. In such an event, sapnagroup reserves the right to charge the Client without honouring any discounts that were previously honoured in good faith. Under no circumstances will sapnagroup be liable for any damages arising from misrepresentation or misinformation.</p></li>
						
						<li><p>sapnagroup reserves the right to refuse service to any Client, if these are not aligned with our business operating principles and policies.</p></li>
						<li><p>sapnagroup provides its services as is, without any guarantees on security or other issues leading to loss of data, sale or reputation. We ensure to the best of our ability that our systems and servers are protected from hackers, viruses, intruders and other online and offline problems, however we will not be held liable for any disruption of services if such situation arises.</p></li>
						<li><p>Relationship of sapnagroup with its suppliers, partners and sub-contractors is of an independent nature. None of the parties have any power, right or authority to interfere or bind the other or assume or create any obligation or responsibility, whether expressed or implied, on behalf of the other or in the other’s name.</p></li>
						<li><p>Client will indemnify and hold sapnagroup, its licensors, content providers, service providers, employees, agents, officers, directors, contractors and sub-contractors (the “Indemnified Parties”) harmless from your breach of any of these Terms And Conditions or any other terms, conditions, policies or procedures herein, including, without limitation, any use of content other than as expressly authorised in these Terms and Conditions. Client agrees that the indemnified parties will have no liability in connection with any such breach or unauthorised use, and you agree to indemnify and hold harmless the Indemnified Parties from any and all resulting loss, damages, judgments, awards, costs, expenses, and attorneys’ fees in connection therewith. You will also indemnify and hold the Indemnified Parties harmless from and against any claims brought by third parties arising out of your use of the information obtained from sapnagroup.</p></li>
						<li><p>sapnagroup will not be liable for any breach of the agreement which is caused by a matter beyond its reasonable control including but not limited to Act of God, fire, lightning, explosion, war, disorder, flood, earth quake, industrial disputes (whether or not involving their employees), extremely severe weather, or acts of local or central government or other competent authorities.</p></li>
					</ul>
				</li>	
					
				<span id="glaw"></span>
				<li><h4>Governing Law</h4>
					<ul className ="listpadding">
						<li><p>Any contract incorporating these Terms and Conditions shall be governed by and construed in accordance with the laws of England and both parties hereby agree to submit to the jurisdiction of the English courts.</p></li>
						
						<li><p>Please note that sapnagroup reserves the right to change the terms and conditions without notice.</p></li>
						
					</ul>
				</li>

				<span id="disclaimer"></span>
				<li><h4>Disclaimer</h4>
					<ul className ="listpadding">
						<li><p>Our standard development services encompass the design, development, and implementation of software solutions as outlined in the agreed-upon project specifications. Please note that services such as penetration testing, security audits, and compliance-related assessments are not included within the standard scope of work unless explicitly stated in our proposal. Should you require these additional services, we recommend a separate discussion to define the specific requirements, scope, and associated budget. This approach ensures that all aspects of your project are addressed appropriately without incurring unnecessary costs.</p></li>						
					</ul>
				</li>					
			</ol>
					</Grid>
				</Grow>
            </Grid>
        </div>
    )
}

export default DevelopmentTerms
