import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import { useForm } from "react-hook-form";
import TextField from '@material-ui/core/TextField';


import Link from '@material-ui/core/Link';

import {display_contactBubble,seo_tags,scroll_top} from '../js/helper.js'

const useStyles = makeStyles((theme) => ({
	container_div: {
		border:'0px solid black'
	},
	page_title: {
		textAlign: 'center',
		fontSize: '18px;'
	},
	form_title: {
		textAlign: 'center',
		fontSize: '14px;',
		margin: '10px',
		fontWeight:'bold',
		border:'1px solid red',
		float:'left'
	},
	form_h2:{
		margin:'10px 0'
	},
	intro_para: {
		textAlign: 'center',
		width: '80%',
		flexBasis: '80%',
		margin:'0 auto 0'
  	},
	contact_button:{
		display: 'inline-block',
		border: '0.5px solid black',
		boxShadow: 'none',
		fontFamily: 'Rajdhani',
		margin: '0 20px 20px',
		padding: '10px 20px 10px 52px',
		minWidth: '232px',
		
		'&:hover':{
			boxShadow: 'none'
		}
	},
	submit_button:{
		border:'0.5px solid black',
		padding:'10px 50px',
		margin:'10px 10px 25px 10px',
		fontFamily:'Rajdhani',
		textAlign:'right',
		boxShadow: 'none',
		'&:hover':{
			boxShadow: 'none'
		}
	},
	country_icons: {
		height: '66px',
		width: '108px',
		margin: '30px auto 10px auto'
	},
	country_address:{
		border: '0px solid black',
		textAlign: 'center',
		marginBottom:'20px',
	},
	form_container:{
		border:'0px solid black'
	},
	textField:{
		display: 'block',
		margin:'0 0 10px',
		width:'100%',
		fontFamily:'Rajdhani'
	},
	submit_message:{
		fontFamily:'Rajdhani',
		color:'#000',
		fontSize:'18px'
	},
	contact_icons:{
		width:'25px',
		height:'auto',
		position:'absolute',
		left:'16px'
	},
	contact_icon_phone:{
		width:'auto',
		height:'28px',
		position:'absolute',
		left:'20px'
	},
	country_office:{
		color:'#000',
		paddingBottom:'10px'
	},
	country_office_link:{
		textDecoration:'underline'
	},
	legal_blurb:{
		padding:'15px 0'
	}
}));

function ContactUs() {
	seo_tags({
      title: 'Contact'
    });
	const classes = useStyles();

	useEffect(() => {
		scroll_top();
		display_contactBubble();
	},[]);

	const [successMessage, showSuccessMessage] = useState(0);

	const { register, handleSubmit, errors } = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		criteriaMode: "firstError",
		shouldFocusError: true,
		shouldUnregister: true,
	})

	

	const submitContactForm = (data) =>{
		 
		// eslint-disable-next-line
		 const res = fetch(process.env.REACT_APP_API_DOMAIN+'contact',{
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
        	body: JSON.stringify(data)
		 });
		
		showSuccessMessage(1)
	}

	//  const submitEmergencyForm = (data) =>{
	// 	 console.log('submitting emergency form');
	// 	 console.log(data);

	// 	 alert('emergency form submitted');
	//  }
	 const mailTo = () =>{
		 let emailto = String.fromCharCode(104,101,108,108,111,64,115,97,112,110,97,103,114,111,117,112,46,99,111,109);
		 window.location = 'mailto:'+ emailto +'?';
		
	 }
	

	var submitMessage = <div className="contactSuccessMessage">
							<Typography className={classes.submit_message}>Your message has been sent.</Typography> 
							<Typography className={classes.submit_message}>We will contact you shortly</Typography>
						</div>
					
	return (
		<React.Fragment>
			<Grid className={`${classes.container_div} maxWrapper`} container px={5}>
				<Grow in={true}>
					<Grid className={classes.academy_title} item xs={12}>
						<CardMedia
							component="img"
							alt="Contact title"
							image="../page-headline/contact.png"
							title="Contact title"
							className="page_header_image"
						/>
					</Grid>
				</Grow>
				<Grow in={true}>
                	<Grid className={classes.intro_para} item xs={12} style={{ marginBottom: '40px' }}>
                    	We believe that personal contact is the key to a successful and long term business relationship. Please get in touch with us so that we can meet you soon! 
                	</Grid>
              	</Grow>

				<Grow in={true}>
                	<Grid className={classes.intro_para} item xs={12} sm={12} md={6} lg={6}>

						<Button xs={12} className={classes.contact_button} variant="contained"  onClick={() => mailTo()}
						 //href="javascript:location.href='mailto:'+String.fromCharCode(104,101,108,108,111,64,115,97,112,110,97,103,114,111,117,112,46,99,111,109)+'?'"
						 >
							<CardMedia
								component="img"
								alt="Contact title"
								image="../social-media/message.png"
								title="Contact title"
								className={classes.contact_icons}
							/>
							hello@sapnagroup.com
						</Button>
						
						<Button md={12} className={classes.contact_button} variant="contained"  href="tel:+44 1737 887808">
							<CardMedia
								component="img"
								alt="Contact title"
								image="../social-media/mobile.png"
								title="Contact title"
								className={classes.contact_icon_phone}
							/>
							+ 44 1737 887808
						</Button>

						<Button md={12} className={classes.contact_button} variant="contained" href="tel:+49 151 14994404">
							<CardMedia
								component="img"
								alt="Contact title"
								image="../social-media/mobile.png"
								title="Contact title"
								className={classes.contact_icon_phone}
							/>
							+ 49 151 14994404
						</Button>

						<Button md={12} className={classes.contact_button} variant="contained"  href="skype:jonnyhubner?call">
							<CardMedia
								component="img"
								alt="Contact title"
								image="../social-media/skype.png"
								title="Contact title"
								className={classes.contact_icons}
							/>
							jonnyhubner
						</Button>
					</Grid>
				</Grow>

				
				<Grow in={true}>
                	<Grid className={classes.form_container} item  xs={12} sm={12} md={6} lg={6}>

						{/* <Grow in={true}>
							<Grid className={classes.form_title} item xs={12}>
								<h2 className={classes.form_h2}>Contact form</h2>
							</Grid>
						</Grow> */}
						
						{/* <form onSubmit={handleSubmit_em(submitEmergencyForm)}>

							<TextField
								required
								id="mobile"
								label="Mobile Number"
								name="mobile"
								value="12345678910"
								variant="outlined"
								className={classes.textField}
								type="text"
								inputRef={register_em}
							/>

							<TextField
								required
								id="name"
								label="Name"
								name="name"
								value="prajyot emergency"
								variant="outlined"
								className={classes.textField}
								type="text"
								inputRef={register_em}
							/>

							<TextField
								required
								id="email"
								type="email"
								name="email"
								value="prajyot@sapnagroup.com"
								label="Email"
								variant="outlined"
								className={classes.textField}
								inputRef={register_em}
							/>

							<TextField
								required
								id="Message"
								label="Message"
								name="message"
								value="test message"
								multiline
								rows={4}
								variant="outlined"
								className={classes.textField}
								inputRef={register_em}
							/>

							<input type="hidden" name="contactType" value="EMERGENCY" ref={register_em} />
							<Button type="submit" md={12} className={classes.submit_button} variant="contained">Send message</Button>
						</form> */}

						{successMessage ? 
						submitMessage:
						<form onSubmit={handleSubmit(submitContactForm)} className="contactForm">
							<TextField
								error={errors.name ? true:false}
								id="name"
								label="Name"
								name="name"
								variant="outlined"
								className={classes.textField}
								type="text"
								inputRef={register({required: "Name required"})}
							/>
					
							<TextField
								error={errors.email ? true:false}
								id="email"
								type="text"
								name="email"
								label="Email"
								variant="outlined"
								className={classes.textField}
								inputRef={register({
									required: "Required",
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										message: "invalid email address"
								}
								})}
							/>

							<TextField
								
								error={errors.message ? true:false}
								id="Message"
								label="Message"
								name="message"
								multiline
								rows={4}
								variant="outlined"
								className={classes.textField}
								inputRef={register({required: true})}
							/>

							<input type="hidden" name="contactType" value="CONTACT" ref={register} />
							<Button type="submit" md={12} className={classes.submit_button} variant="contained">
								<CardMedia
									component="img"
									alt="Contact title"
									image="../social-media/message.png"
									title="Contact title"
									className={classes.contact_icons}
								/>
								Send message
							</Button>
						</form>
					
						}
						</Grid>
				</Grow>
				
				<Grow in={true}>
					<Grid className={`${classes.intro_para} flagCube`} item xs={12} sm={12} md={4} lg={4} style={{ padding: '0 40px 15px' }}>
						<CardMedia
							className={classes.country_icons}
							image="/contact-icons/england.png"
							title="UK flag"
						/>

						<div className={classes.country_address}>
							<div className={classes.country_office}>
								The Old Wheel <br></br>
								31/37 Church Street<br></br>
								Reigate, RH2 0AD,<br></br>
								United Kingdom
							</div>
							<Link href="https://maps.google.com/maps/place/51%C2%B014'17.1%22N+0%C2%B012'13.9%22W/@51.2381605,-0.2049286,18z/data=!4m5!3m4!1s0x0:0x0!8m2!3d51.238073!4d-0.203854" color="inherit" className={classes.country_office_link} target="_blank" rel="noreferrer">
								See on map
							</Link>
						</div>
					</Grid>
				</Grow>
				<Grow in={true}>
					<Grid className={`${classes.intro_para} flagCube`} item xs={12} sm={12} md={4} lg={4} style={{ padding: '0 40px 15px' }}>
						<CardMedia
							className={classes.country_icons}
							image="/contact-icons/germany.png"
							title="Germany flag"
						/>

						<div className={classes.country_address}>
							<div className={classes.country_office}>
								Robert-Bosch-Str. 1<br></br>
								Wunderburg<br></br>
								96050 Bamberg,<br></br>
								Germany
							</div>

							<Link href="https://goo.gl/maps/rLVHysQRAqAzuWfy8" color="inherit" className={classes.country_office_link} target="_blank" rel="noreferrer">
								See on map
							</Link>
						</div>
					</Grid>
				</Grow>
				<Grow in={true}>
					<Grid className={`${classes.intro_para} flagCube`} item xs={12} sm={12} md={4} lg={4} style={{ padding: '0 40px 15px' }}>
						<CardMedia
							className={classes.country_icons}
							image="/contact-icons/india.png"
							title="India flag"
						/>

						<div className={classes.country_address}>
							<div className={classes.country_office}>
								307 Kamat Grand,<br></br>
								Behind Caculo Mall,<br></br>
								St-Inez, Panaji,<br></br>
								Goa, India 403001
							</div>

							<Link href="https://www.google.com/maps/place/sapnagroup/@15.4859205,73.8155977,17z/data=!3m1!4b1!4m5!3m4!1s0x3bbfc08d5e516059:0x210a024632810473!8m2!3d15.4859153!4d73.8177864" color="inherit" target="_blank" rel="noreferrer" className={classes.country_office_link}>
								See on map
							</Link>
						</div>
					</Grid>
				</Grow>

				<Grow in={true}>
                	<Grid className={classes.legal_blurb} item xs={12}>
					sapnagroup Ltd. (UK), sapnagroup deutschland UG (DE), Sapnatech India LLP (India) and iDEAS unlimited (India) are trading as sapnagroup; sapnagroup Ltd - Registered office address: The Old Wheel House, 31/37 Church Street, Reigate, RH2 0AD; Registered in England, Company No. 4533465, VAT No GB 802 7568 29; Director: Claus Hübner; sapnagroup deutschland UG, Robert-Bosch-Straße 1, 96050 Bamberg, Sitz Bamberg, Amtsgericht Bamberg, HRB 10112, Steuernummer 207/137/00445 ; Umsatzsteuer-Identifikationsnummer: DE338482822; Geschäftsführer: Claus Hübner; Sapnatech India LLP- 307 Kamat Grand, Behind Caculo Mall, St-Inez, Panaji, Goa, India 403001; Partners: Nilesh Nayak & Anurag Jain; iDEAS unlimited - Vaidya Hospital, Gov De Pestana Road, Panaji, Goa, India, 403001; Proprietor: Chandrashekhar Vaidya
					</Grid>
				</Grow>
			</Grid>	
		</React.Fragment>
	)
}

export default ContactUs
